import Router from "./route/Index";

import ThemeProvider from "./layout/provider/Theme";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Auth } from "./firebase/firebase";
import { Logout } from "./firebase/functions/auth";
import { useDispatch } from "react-redux";
import { setUserData } from "./redux/slices/UserSlice";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { GetAdminUserDetails } from "./firebase/functions/adminUsers";
import { useNavigate } from "react-router";

const App = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fetchingUserData, setFetchingUserData] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(true);

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(Auth, (user) => {
      setFetchingUserData(true);
      if (user) {
        GetAdminUserDetails(user.uid)
          .then((res) => {
            if (res?.userData?.role === "admin"
              && res?.userData?.status?.toLowerCase() === "active") {
              setIsLoggedIn(true);
              setFetchingUserData(false);
              setIsEmailVerified(true);
              setUser(res.userData);
              dispatch(
                setUserData({
                  user: res.userData,
                })
              );

              // Set New user Cookie
              document.cookie = `user=${JSON.stringify({
                ...res.userData,
                photoURL: encodeURIComponent(res?.userData?.photoURL ?? ""),
              })}; domain=${process.env.REACT_APP_MAIN_WEBSITE_DOMAIN}; path=/; max-age=604800;`;
            } else if (res?.userData?.role === "admin"
              && res?.userData?.status?.toLowerCase() !== "active"){
              if(res?.userData?.status?.toLowerCase() === "pending") {
                toast.warn("Your account is pending for approval. Please wait for admin approval.");
              } else if(res?.userData?.status?.toLowerCase() === "suspend") {
                toast.warn("Your account has been suspended. Please contact admin for more information.");
              }
              clearDataForInvalidLogin();
            } else {
              toast.warn("You are not authorized to access admin dashboard.");
              clearDataForInvalidLogin();
            }
          })
          .catch((error) => {
            toast.error(error?.message ?? "Something went wrong");
            clearDataForInvalidLogin();
          });
      } else {
        clearDataForInvalidLogin();
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearDataForInvalidLogin = () => {
    Logout();
    setIsLoggedIn(false);
    setFetchingUserData(false);
    setIsEmailVerified(true);
    setUser(null);
    dispatch(
      setUserData({
        user: null,
      })
    );
    // Remove cookie
    document.cookie = `user=; domain=${process.env.REACT_APP_MAIN_WEBSITE_DOMAIN}; path=/;`;
    navigator("/");
  };

  return (
    <ThemeProvider>
      {fetchingUserData ? (
        <>
          <section className="d-flex justify-content-center align-items-center vh-100">
            <Spinner size="lg" color="primary" />
          </section>
        </>
      ) : (
        <>
          {isLoggedIn ? (
            <Router authenticated={isLoggedIn} emailVerified={isEmailVerified} />
          ) : (
            <Router authenticated={false} emailVerified={false} />
          )}
        </>
      )}
    </ThemeProvider>
  );
};
export default App;
