import React, { useEffect, useState } from "react";
import { Form, Modal, ModalBody } from "reactstrap";
import { Button, Col, Icon } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddPricePlan } from "../../../firebase/functions/pricePlan";
import { v4 as uuidv4 } from "uuid";

const emptyFormData = {
  title: "",
  description: "",
  monthlyUSD: 0,
  yearlyUSD: 0,
  monthlyTomans: 0,
  yearlyTomans: 0,
  isPopular: false,
  isForFarsiUsers: false,
};

function AddPricingPlanModel({ modal, setModal, refetchData }) {
  const {
    reset,
    register,
    formState: { errors },
  } = useForm();

  const [addingPricePlan, setAddingPricePlan] = useState(false);

  const [formData, setFormData] = useState(emptyFormData);

  const onFormReset = () => {
    setModal({ add: false });
    setFormData(emptyFormData);
  };

  const [featureList, setFeatureList] = useState([""]);

  const addPricePlan = async () => {
    if (
      formData?.title?.trim() !== "" &&
      formData?.description?.trim() !== "" &&
      formData?.monthlyUSD > 0 &&
      formData?.yearlyUSD > 0 &&
      formData?.monthlyTomans > 0 &&
      formData?.yearlyTomans > 0 &&
      featureList.length > 0 &&
      featureList?.every((feature) => feature.trim() !== "")
    ) {
      try {
        setAddingPricePlan(true);
        const features = featureList.filter((feature) => feature.trim() !== "");
        const pricePlan = {
          id: uuidv4(),
          title: formData.title,
          description: formData.description,
          monthlyUSD: formData.monthlyUSD,
          yearlyUSD: formData.yearlyUSD,
          monthlyTomans: formData.monthlyTomans,
          yearlyTomans: formData.yearlyTomans,
          features: features,
          isPopular: formData.isPopular,
          isForFarsiUsers: formData.isForFarsiUsers,
        };

        const res = await AddPricePlan(pricePlan);

        if (res.status === "success") {
          toast.success(res.message);
          refetchData();
          setModal({ add: false });
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setAddingPricePlan(false);
      }
    } else {
      toast.error("Please fill all the fields");
      setAddingPricePlan(false);
      return;
    }
  };

  useEffect(() => {
    reset(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            onFormReset();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <div className="p-2">
          <h5 className="title">
            Add New Pricing Plan
            <span className="fs-6 fw-normal text-muted d-block mt-1">
              Fill in the details to add a new pricing plan
            </span>
          </h5>
          <div className="mt-4">
            <Form
              className="row gy-4 mt-4"
              onSubmit={(e) => {
                e.preventDefault();
                addPricePlan();
              }}
            >
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Title</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("title", { required: "This field is required" })}
                    value={formData.title}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    placeholder="Enter title"
                  />
                  {errors.title && <span className="invalid">{errors.title.message}</span>}
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Description</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("description", { required: "This field is required" })}
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    placeholder="Enter description"
                  />
                  {errors.description && <span className="invalid">{errors.description.message}</span>}
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Monthly Price in USD</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("monthlyUSD", { required: "This field is required" })}
                    value={formData.monthlyUSD}
                    onChange={(e) => setFormData({ ...formData, monthlyUSD: e.target.value })}
                    placeholder="Enter monthly price in USD"
                  />
                  {errors.monthlyUSD && <span className="invalid">{errors.monthlyUSD.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Yearly Price in USD</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("yearlyUSD", { required: "This field is required" })}
                    value={formData.yearlyUSD}
                    onChange={(e) => setFormData({ ...formData, yearlyUSD: e.target.value })}
                    placeholder="Enter yearly price in USD"
                  />
                  {errors.yearlyUSD && <span className="invalid">{errors.yearlyUSD.message}</span>}
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Monthly Price in Tomans (IRR)</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("monthlyTomans", { required: "This field is required" })}
                    value={formData.monthlyTomans}
                    onChange={(e) => setFormData({ ...formData, monthlyTomans: e.target.value })}
                    placeholder="Enter monthly price in Tomans (IRR)"
                  />
                  {errors.monthlyTomans && <span className="invalid">{errors.monthlyTomans.message}</span>}
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Yearly Price in Tomans (IRR)</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("yearlyTomans", { required: "This field is required" })}
                    value={formData.yearlyTomans}
                    onChange={(e) => setFormData({ ...formData, yearlyTomans: e.target.value })}
                    placeholder="Enter yearly price in Tomans (IRR)"
                  />
                  {errors.yearlyTomans && <span className="invalid">{errors.yearlyTomans.message}</span>}
                </div>
              </Col>

              <Col md="12">
                <div className="form-group flex align-items-center gap-1">
                  <input
                    type="checkbox"
                    {...register("isPopular")}
                    name="isPopularCheck"
                    id="isPopularCheck"
                    value={formData.isPopular}
                    onChange={(e) => setFormData({ ...formData, isPopular: e.target.checked })}
                  />
                  <label className="form-label mx-1" htmlFor="isPopularCheck">
                    Show Popular Badge
                  </label>
                </div>
              </Col>

              <Col md="12">
                <div className="form-group flex align-items-center gap-1">
                  <input
                    type="checkbox"
                    {...register("isForFarsiUsers")}
                    name="isForFarsiUsersCheck"
                    id="isForFarsiUsersCheck"
                    value={formData.isForFarsiUsers}
                    onChange={(e) => setFormData({ ...formData, isForFarsiUsers: e.target.checked })}
                  />
                  <label className="form-label mx-1" htmlFor="isForFarsiUsersCheck">
                    Is for Farsi Users
                  </label>
                </div>
              </Col>

              <Col md="12">
                <label className="form-label">Features</label>
                <div
                  className="flex flex-column"
                  style={{
                    height: "fit-content",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {featureList.map((feature, index) => {
                    return (
                      <div key={index} className="flex align-items-center position-relative my-1">
                        <input
                          type="text"
                          className="form-control"
                          value={feature}
                          onChange={(e) => {
                            featureList[index] = e.target.value;
                            setFeatureList([...featureList]);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              setFeatureList([...featureList, ""]);
                            }
                          }}
                          placeholder="Enter feature"
                        />
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-icon position-absolute end-0 top-0"
                            onClick={() => {
                              featureList.splice(index, 1);
                              setFeatureList([...featureList]);
                            }}
                          >
                            <Icon name="trash-fill"></Icon>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
                <button
                  type="button"
                  className="btn btn-md w-100 justify-content-center"
                  onClick={() => {
                    setFeatureList([...featureList, ""]);
                  }}
                >
                  <Icon name="plus-round" className="" />{" "}
                  <span
                    style={{
                      margin: "0px",
                      padding: "0px",
                      marginLeft: "5px",
                      marginBottom: "-1px",
                    }}
                  >
                    Add New Feature
                  </span>
                </button>
              </Col>

              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit" disabled={false}>
                      {addingPricePlan ? "Adding..." : "Add Plan"}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onFormReset();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default AddPricingPlanModel;
