import { addDoc, collection, deleteDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { Auth, DB } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";

const defaultProfile = {
  name: "",
  displayName: "",
  dob: new Date().toISOString(),
  email: "",
  phone: "",
};

// Get All Admin Users
const GetAllAdminUsers = async () => {
  try {
    const q = query(collection(DB, "admin-users"));
    const docs = await getDocs(q);
    const usersData = docs.docs.map((doc) => doc.data());

    return {
      result: "success",
      message: "Admin Users data fetched Successfully",
      data: usersData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message || "Users not found",
      data: [],
    };
  }
};

// Get Admin User Details by UID
const GetAdminUserDetails = async (uid) => {
  try {
    const q = query(collection(DB, "admin-users"), where("uid", "==", uid));
    const docs = await getDocs(q);
    const userData = docs.docs[0].data();

    return {
      result: "success",
      message: "User data fetched Successfully",
      userData: userData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message || "User not found",
      userData: null,
    };
  }
};

const UpdateAdminUser = async (uid, data) => {
  try {
    const q = query(collection(DB, "admin-users"), where("uid", "==", uid));
    const docs = await getDocs(q);
    const userRef = docs.docs[0].ref;

    await updateDoc(userRef, data);

    return {
      result: "success",
      message: "User profile updated successfully",
      data: data,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message || "Could not update user profile",
      data: null,
    };
  }
};

const DeleteAdminUser = async (uid) => {
  try {
    const q = query(collection(DB, "admin-users"), where("uid", "==", uid));
    const docs = await getDocs(q);
    const userRef = docs.docs[0].ref;



    await deleteDoc(userRef);

    return {
      result: "success",
      message: "User deleted successfully",
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message || "Could not delete user",
    };
  }
};

const AddAdminUser = async (data) => {
  try {
    const q = query(collection(DB, "admin-users"), where("email", "==", data.email));
    const docs = await getDocs(q);
    if (docs.docs.length > 0) {
      return {
        result: "error",
        message: "User already exists",
      };
    }

    const user = await createUserWithEmailAndPassword(Auth, data.email, data.password);

    // Store user in database
    await addDoc(collection(DB, "admin-users"), {
      ...defaultProfile,
      uid: user.user.uid,
      photoURL: null,
      email: data.email,
      fullName: data.name,
      name: data.name,
      phone: data.phoneNo,
      displayName: data.name,
      dob: new Date().toISOString(),
      role: "admin",
      emailVerified: true,
      status: "active",
    });

    return {
      result: "success",
      message: "User added successfully",
    };
  } catch (error) {
    console.log(error);
    return {
      result: "error",
      message: error.message || "Could not add user",
    };
  }
};

export { GetAllAdminUsers, GetAdminUserDetails, UpdateAdminUser, DeleteAdminUser, AddAdminUser };
