import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Badge,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  RSelect,
} from "../../../components/Component";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../utils/Utils";
import { GetUserData } from "../../../firebase/functions/profile";
import { toast } from "react-toastify";
import EditModal from "./EditModal";
import { filterStatus } from "./UserData";
import { UpdateUser } from "../../../firebase/functions/users";
import {
  DeleteTransaction,
  GetTransactionsOfUser,
  UpdateTransactionStatus,
} from "../../../firebase/functions/transactions";
import AddInvoiceModel from "../order-invoice/AddInvoiceModel";

const UserDetailsPage = ({ match }) => {
  let { userId } = useParams();
  const [user, setUser] = useState(null);
  const [userTransactions, setUserTransactions] = useState([]);
  const navigate = useNavigate();
  const [fetchingData, setFetchingData] = useState(false);
  const [currentTab, setCurrentTab] = useState("personal");

  const [viewModal, setViewModal] = useState(false);
  const [detail, setDetail] = useState({});

  const [updatingUserData, setUpdatingUserData] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editFormData, setEditFormData] = useState({
    name: "",
    email: "",
    balance: 0,
    phone: "",
    status: "",
    tradingPlatformId: "",
  });

  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
  };

  // function to reset the form
  const resetForm = () => {
    setEditFormData({
      name: "",
      email: "",
      balance: 0,
      phone: "",
      status: "Active",
      tradingPlatformId: "",
    });
  };

  const openEditModal = () => {
    setModal({ edit: true });
    setEditFormData({
      balance: 0,
      email: user.email,
      name: user.name,
      phone: user.phone,
      status: user.status,
      tradingPlatformId: user.tradingPlatformId,
    });
  };

  // submit function to update a new item
  const onEditSubmit = async (submitData) => {
    const { name, email, phone } = submitData;

    setUpdatingUserData(true);

    const updatedData = {
      ...user,
      name: name,
      email: email,
      phone: phone,
      status: editFormData.status,
      tradingPlatformId: editFormData.tradingPlatformId,
    };

    const res = await UpdateUser(userId, updatedData);
    if (res.result === "success") {
      fetchUserData(userId);
      toast.success(res.message);
      setUpdatingUserData(false);
      setModal({ edit: false });
      Promise.resolve();
    } else {
      toast.error(res.message);
      setUpdatingUserData(false);
      Promise.reject();
    }
  };

  const fetchUserData = async (id) => {
    try {
      setFetchingData(true);
      const res = await GetUserData(id);
      const transactionsRes = await GetTransactionsOfUser(id);

      if (res.result === "success") {
        setUser(res.userData);
        console.log(res.userData);
      } else {
        toast.error(res.message);
        setUser(null);
      }

      if (transactionsRes.result === "success") {
        setUserTransactions(transactionsRes.transactions);
        console.log(transactionsRes.transactions);
      } else {
        setUserTransactions([]);
        toast.error(transactionsRes.message);
      }
    } catch (error) {
      setUser(null);
      setUserTransactions([]);
      toast.error(error?.message ?? "An error occurred");
    } finally {
      setFetchingData(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData(userId);
    }
  }, [userId]);

  const formatTransactionPrice = (transaction) => {
    if (transaction?.planData?.planType === "yearly") {
      if (transaction?.planData?.currency === "USD") {
        return `USD ${transaction?.planData?.yearlyUSD ?? transaction.amount}`;
      } else {
        return `IRR ${transaction?.planData?.yearlyTomans ?? transaction.amount}`;
      }
    } else {
      if (transaction?.planData?.currency === "USD") {
        return `USD ${transaction?.planData?.monthlyUSD ?? transaction.amount}`;
      } else {
        return `IRR ${transaction?.planData?.monthlyTomans ?? transaction.amount}`;
      }
    }
  };

  // function to load detail data
  const loadDetail = (id) => {
    let index = userTransactions.findIndex((item) => item.id === id);
    setDetail(userTransactions[index]);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const deleteTransaction = async (paymentId) => {
    try {
      setIsDeleting(true);
      const res = await DeleteTransaction(paymentId);

      if (res.result === "success") {
        toast.success(res.message);
        fetchUserData(userId);
        setShowDeleteModal(false);
        setDetail({});
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message ?? "An error occurred");
    } finally {
      setIsDeleting(false);
    }
  };

  const updateTransaction = async (paymentId, status) => {
    try {
      setIsUpdating(true);
      const res = await UpdateTransactionStatus(paymentId, status);

      if (res.result === "success") {
        toast.success(res.message);
        fetchUserData(userId);
        setShowUpdateModal(false);
        setDetail({});
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message ?? "An error occurred");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="User Details - Regular"></Head>
      {fetchingData ? (
        <Content>
          <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
            <Spinner size="lg" color="primary" />
          </section>
        </Content>
      ) : (
        user &&
        userTransactions && (
          <Content>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle tag="h3" page>
                    Users / <strong className="text-primary small">{user.name}</strong>
                    <Badge
                      color={user.status === "Active" ? "success" : user.status === "Pending" ? "warning" : "danger"}
                      size="md"
                      className="mx-2"
                    >
                      {user.status}
                    </Badge>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        User ID: <span className="text-base">{user.uid}</span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Button
                    color="light"
                    outline
                    className="bg-white d-none d-sm-inline-flex"
                    onClick={() => navigate(-1)}
                  >
                    <Icon name="arrow-left"></Icon>
                    <span>Back</span>
                  </Button>
                  <a
                    href="#back"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(-1);
                    }}
                    className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                  >
                    <Icon name="arrow-left"></Icon>
                  </a>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Card className="card-bordered">
                <div className="card-aside-wrap" id="user-detail-block">
                  <div className="card-content">
                    <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${currentTab === "personal" ? "active" : ""}`}
                          href="#personal"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setCurrentTab("personal");
                          }}
                        >
                          <Icon name="user-circle"></Icon>
                          <span>Personal</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${currentTab === "transactions" ? "active" : ""}`}
                          href="#transactions"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setCurrentTab("transactions");
                          }}
                        >
                          <Icon name="repeat"></Icon>
                          <span>Transactions</span>
                        </a>
                      </li>
                    </ul>

                    {currentTab === "personal" && (
                      <div className="card-inner">
                        <Block>
                          <BlockHead className="d-flex align-items-center gap-1">
                            <BlockTitle tag="h5">Personal Information</BlockTitle>
                            <button
                              className="nav-link active text-primary"
                              style={{
                                width: "fit-content",
                                height: "fit-content",
                                marginTop: "-5px",
                              }}
                              onClick={() => {
                                openEditModal();
                              }}
                            >
                              <Icon name="edit"></Icon>
                            </button>
                          </BlockHead>
                          <div className="profile-ud-list">
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">Full Name</span>
                                <span className="profile-ud-value">{user.name}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">Email Address</span>
                                <span className="profile-ud-value">{user.email}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">Mobile Number</span>
                                <span className="profile-ud-value">{user.phone}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">Date of Birth</span>
                                <span className="profile-ud-value">{formatDate(user.dob, "DD MMM YYYY")}</span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">Trading Platform Id</span>
                                <span className="profile-ud-value">{user?.tradingPlatformId ?? "N/A"}</span>
                              </div>
                            </div>
                          </div>
                        </Block>

                        <Block>
                          <BlockHead className="nk-block-head-line">
                            <BlockTitle tag="h6" className="overline-title text-base">
                              Address Information
                            </BlockTitle>
                          </BlockHead>
                          <div className="profile-ud-list">
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">Country</span>
                                <span className="profile-ud-value">{user.country}</span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">State</span>
                                <span className="profile-ud-value">{user.state}</span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">Address</span>
                                <span className="profile-ud-value">{user.address}</span>
                              </div>
                            </div>

                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">Address 2</span>
                                <span className="profile-ud-value">{user.address2}</span>
                              </div>
                            </div>
                          </div>
                        </Block>
                      </div>
                    )}

                    {currentTab === "transactions" && (
                      <div className="card-inner">
                        <Block>
                          <BlockHead className="d-flex align-items-center gap-1 mt-2 mb-5">
                            <BlockTitle tag="h5">Transactions</BlockTitle>
                            <Button
                              color="light"
                              outline
                              className="bg-white d-none d-sm-inline-flex"
                              style={{
                                marginTop: "-6px",
                              }}
                              onClick={() => {
                                setModal({
                                  add: true,
                                  edit: false,
                                });
                              }}
                            >
                              <Icon name="plus"></Icon>
                              <span>Add New</span>
                            </Button>
                          </BlockHead>
                        </Block>

                        <div className="card-inner p-0">
                          <table className="table table-tranx">
                            <thead>
                              <tr className="tb-tnx-head">
                                <th className="tb-tnx-id">
                                  <span className="">ID</span>
                                </th>
                                <th className="tb-tnx-info">
                                  <span className="tb-tnx-desc d-none d-sm-inline-block">
                                    <span>Plan</span>
                                  </span>
                                  <span className="tb-tnx-date d-md-inline-block d-none">
                                    <span className="">Date</span>
                                  </span>
                                </th>
                                <th className="tb-tnx-amount is-alt">
                                  <span className="tb-tnx-total">Total</span>
                                  <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                                </th>
                                <th className="tb-tnx-action">
                                  <span>&nbsp;</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {userTransactions.length > 0 ? (
                                userTransactions.map((item) => {
                                  return (
                                    <tr key={item.id} className="tb-tnx-item">
                                      <td className="tb-tnx-id">
                                        <a
                                          href="#ref"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            loadDetail(item.id);
                                            setViewModal(true);
                                          }}
                                        >
                                          <span>{item.paymentId}</span>
                                        </a>
                                      </td>
                                      <td className="tb-tnx-info">
                                        <div className="tb-tnx-desc">
                                          <span
                                            className="title"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {item?.planData?.title} - {item?.planData?.planType}
                                          </span>
                                        </div>
                                        <div className="tb-tnx-date">
                                          <span className="date">{formatDate(item.date, "DD MMM YYYY")}</span>
                                        </div>
                                      </td>
                                      <td className="tb-tnx-amount is-alt">
                                        <div className="tb-tnx-total">
                                          <span className="amount">{formatTransactionPrice(item)}</span>
                                        </div>
                                        <div className="tb-tnx-status">
                                          <Badge
                                            className="badge-dot"
                                            color={
                                              item.status === "paid"
                                                ? "success"
                                                : item.status === "pending"
                                                ? "warning"
                                                : "danger"
                                            }
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {item.status}
                                          </Badge>
                                        </div>
                                      </td>
                                      <td className="tb-tnx-action">
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            tag="a"
                                            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                          >
                                            <Icon name="more-h"></Icon>
                                          </DropdownToggle>
                                          <DropdownMenu end>
                                            <ul className="link-list-plain">
                                              <li
                                                onClick={() => {
                                                  loadDetail(item.id);
                                                  setViewModal(true);
                                                }}
                                              >
                                                <DropdownItem
                                                  tag="a"
                                                  href="#view"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                  }}
                                                >
                                                  View
                                                </DropdownItem>
                                              </li>

                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#update"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setShowUpdateModal(true);
                                                    loadDetail(item.id);
                                                    setSelectedStatus({
                                                      label: item.status,
                                                      value: item.status,
                                                    });
                                                  }}
                                                >
                                                  Update Status
                                                </DropdownItem>
                                              </li>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#delete"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setShowDeleteModal(true);
                                                    loadDetail(item.id);
                                                  }}
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </li>
                                            </ul>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    No transactions found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </Block>

            {/* Edit User Modal */}
            <EditModal
              modal={modal.edit}
              formData={editFormData}
              setFormData={setEditFormData}
              closeModal={closeEditModal}
              onSubmit={onEditSubmit}
              filterStatus={filterStatus}
              updating={updatingUserData}
            />

            {/* View Transaction Modal */}
            <Modal isOpen={viewModal} toggle={() => setViewModal(false)} className="modal-dialog-centered" size="lg">
              <ModalBody>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setViewModal(false);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <div className="nk-modal-head">
                  <h4 className="nk-modal-title title">
                    Transaction <small className="text-primary">#{detail?.paymentId}</small>
                  </h4>
                </div>
                <div className="nk-tnx-details mt-sm-3">
                  <Row className="gy-3">
                    <Col lg={6}>
                      <span className="sub-text">User Name</span>
                      <Link to={`/user/${detail.userId}`} className="caption-text">
                        {detail?.username}
                      </Link>
                    </Col>

                    <Col lg={6}>
                      <span className="sub-text">User Email</span>
                      <span className="caption-text">{detail.email}</span>
                    </Col>

                    <Col lg={6}>
                      <span className="sub-text">Plan Name</span>
                      <span className="caption-text">{detail?.planData?.title}</span>
                    </Col>
                    <Col lg={6}>
                      <span className="sub-text">Plan Duration</span>
                      <span className="caption-text" style={{ textTransform: "capitalize" }}>
                        {detail?.planData?.planType}
                      </span>
                    </Col>

                    <Col lg={6}>
                      <span className="sub-text">Amount</span>
                      <span className="caption-text">{formatTransactionPrice(detail)}</span>
                    </Col>

                    <Col lg={6}>
                      <span className="sub-text">Status</span>
                      <Badge
                        color={
                          detail.status === "paid" ? "success" : detail.status === "pending" ? "warning" : "danger"
                        }
                        size="md"
                        style={{ textTransform: "capitalize" }}
                      >
                        {detail.status}
                      </Badge>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
            </Modal>

            <AddInvoiceModel
              modal={modal}
              setModal={setModal}
              userUid={user.uid}
              user={{
                uid: user.uid,
                name: user.fullName,
                email: user.email,
                phone: user.phone,
              }}
              refetchData={() => {
                console.log("fetching user data");
                fetchUserData(userId);
              }}
            />

            {/* Delete Transaction Modal */}
            <Modal
              isOpen={showDeleteModal}
              toggle={() => setShowDeleteModal(false)}
              className="modal-dialog-centered"
              size="md"
            >
              <ModalBody>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setShowDeleteModal(false);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <div className="nk-modal-head">
                  <h4 className="nk-modal-title title">
                    Are you sure you want to delete this transaction{" "}
                    <span className="text-primary">{detail?.paymentId}</span>?
                  </h4>
                </div>

                <div className="nk-modal-head mt-4">
                  <Button
                    className="btn btn-primary"
                    disabled={isDeleting}
                    onClick={() => {
                      deleteTransaction(detail?.paymentId);
                    }}
                  >
                    {isDeleting ? <Spinner size="sm" color="light" /> : "Delete Transaction"}
                  </Button>

                  <Button
                    onClick={() => setShowDeleteModal(false)}
                    className="btn btn-outline-secondary mx-2"
                    disabled={isDeleting}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalBody>
            </Modal>

            {/* Update Transaction Status Modal */}
            <Modal
              isOpen={showUpdateModal}
              toggle={() => setShowUpdateModal(false)}
              className="modal-dialog-centered"
              size="md"
            >
              <ModalBody>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setShowUpdateModal(false);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <div className="nk-modal-head">
                  <h4 className="nk-modal-title title">
                    Update Transaction Status for <span className="text-primary">{detail?.paymentId}</span>
                  </h4>
                </div>

                <div className="nk-modal-head mt-4">
                  <RSelect
                    options={[
                      {
                        label: "Paid",
                        value: "paid",
                      },
                      {
                        label: "Pending",
                        value: "pending",
                      },
                      {
                        label: "Deleted",
                        value: "deleted",
                      },
                      {
                        label: "Canceled",
                        value: "canceled",
                      },
                    ]}
                    value={
                      !selectedStatus
                        ? {
                            label: "Select Status",
                            value: "",
                          }
                        : selectedStatus
                    }
                    onChange={(e) => {
                      setSelectedStatus(e);
                    }}
                  />
                </div>

                <div className="nk-modal-head mt-4">
                  <Button
                    className="btn btn-primary"
                    disabled={isUpdating}
                    onClick={() => {
                      updateTransaction(detail?.paymentId, selectedStatus?.value);
                    }}
                  >
                    {isUpdating ? <Spinner size="sm" color="light" /> : "Update Status"}
                  </Button>

                  <Button
                    onClick={() => setShowUpdateModal(false)}
                    className="btn btn-outline-secondary mx-2"
                    disabled={isUpdating}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Content>
        )
      )}
    </React.Fragment>
  );
};
export default UserDetailsPage;
