import { addDoc, collection, deleteDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { DB } from "../firebase";

const AddPricePlan = async (data) => {
  try {
    // Store user in database
    await addDoc(collection(DB, "price-plans"), {
      ...data,
    });

    return {
      status: "success",
      message: "Price plan added successfully",
    };
  } catch (error) {
    return {
      status: "error",
      message: error.message || "Could not add price plan",
    };
  }
};

const UpdatePricePlan = async (data) => {
  try {
    // Get a reference to the transaction
    const q = query(collection(DB, "price-plans"), where("id", "==", data?.id ?? ""));
    const docs = await getDocs(q);
    const plan = docs.docs[0];

    if (!plan) {
      throw new Error("Price plan not found");
    }

    // Update the transaction status to canceled
    await updateDoc(plan.ref, {
      ...data,
    });

    return {
      status: "success",
      message: "Price plan updated successfully",
    };
  } catch (error) {
    return {
      status: "error",
      message: error.message || "Could not update price plan",
    };
  }
};

const DeletePricePlan = async (id) => {
  if (!id) {
    throw new Error("Price plan ID is required");
  }

  try {
    // Get a reference to the transaction
    const q = query(collection(DB, "price-plans"), where("id", "==", id ?? ""));
    const docs = await getDocs(q);
    const plan = docs.docs[0];

    if (!plan) {
      throw new Error("Price plan not found");
    }

    const planRef = plan.ref;

    await deleteDoc(planRef);

    return {
      status: "success",
      message: "Price plan deleted successfully",
    };
  } catch (error) {
    return {
      status: "error",
      message: error.message || "Could not delete price plan",
    };
  }
};

const GetAllPricePlans = async () => {
  try {
    const res = await getDocs(collection(DB, "price-plans"));
    const data = res.docs.map((doc) => doc.data());
    return {
      status: "success",
      message: "Price plans fetched successfully",
      data: data,
    };
  } catch (error) {
    return {
      status: "error",
      message: error.message || "Could not fetch price plans",
      data: null,
    };
  }
};

export { GetAllPricePlans, AddPricePlan, UpdatePricePlan, DeletePricePlan };
