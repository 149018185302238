import { collection, deleteDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { DB } from "../firebase";

// Get All Users
const GetAllUsers = async () => {
  try {
    const q = query(collection(DB, "users"));
    const docs = await getDocs(q);
    const usersData = docs.docs.map((doc) => doc.data());

    return {
      result: "success",
      message: "Users data fetched Successfully",
      data: usersData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message || "Users not found",
      data: [],
    };
  }
};

// Get User Details by UID
const GetUserDetails = async (uid) => {
  try {
    const q = query(collection(DB, "users"), where("uid", "==", uid));
    const docs = await getDocs(q);
    const userData = docs.docs[0].data();

    return {
      result: "success",
      message: "User data fetched Successfully",
      data: userData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message || "User not found",
      data: null,
    };
  }
};

const UpdateUser = async (uid, data) => {
  try {
    const q = query(collection(DB, "users"), where("uid", "==", uid));
    const docs = await getDocs(q);
    const userRef = docs.docs[0].ref;

    await updateDoc(userRef, data);

    return {
      result: "success",
      message: "User profile updated successfully",
      data: data,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message || "Could not update user profile",
      data: null,
    };
  }
};

const DeleteUser = async (uid) => {
  try {
    const q = query(collection(DB, "users"), where("uid", "==", uid));
    const docs = await getDocs(q);
    const userRef = docs.docs[0].ref;

    await deleteDoc(userRef);

    return {
      result: "success",
      message: "User deleted successfully",
    };
  } catch (err) {
    return {
      result: "error",
      message: err.message || "Could not delete user",
    };
  }
};

export { GetAllUsers, GetUserDetails, UpdateUser, DeleteUser };
