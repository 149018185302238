import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";
import { Button } from "../../components/Component";
import { ResendVerificationEmail } from "../../firebase/functions/auth";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

const EmailVerification = () => {
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const requestVerificationEmail = async () => {
    try {
      setIsSendingEmail(true);
      const res = await ResendVerificationEmail();

      if (res.result === "error") {
        toast.error(res.message);
      } else {
        toast.success("Verification email sent");
      }
      setIsSendingEmail(false);
    } catch (error) {
      setIsSendingEmail(false);
      toast.error(error?.message ?? "Cannot send verification email");
    }
  };

  return (
    <>
      <Head title="Success" />
      <Block className="nk-block-middle nk-auth-body">
        <div className="brand-logo pb-5">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h4">Thank you for Signing Up</BlockTitle>
            <BlockDes className="text-success">
              <p>
                We have sent you an email with a verification link. Please check your email inbox and click on the link
                to verify your email address.
              </p>
              <BlockTitle tag="h6">
                Didn't receive the email? <br />
                <small>Check your spam folder or</small>
              </BlockTitle>

              <Button
                color="primary"
                size="lg"
                onClick={() => {
                  requestVerificationEmail();
                }}
                disabled={isSendingEmail}
              >
                {isSendingEmail ? <Spinner size="sm" color="light" /> : "Resend Email"}
              </Button>
            </BlockDes>
          </BlockContent>
        </BlockHead>
      </Block>
      <AuthFooter />
    </>
  );
};
export default EmailVerification;
